import _ from 'lodash';
import ts from "typescript";

export const createFunctionByString = (customFunction: string) => {
    const result = ts.transpile(customFunction);
    // tslint:disable-next-line: no-eval
    return eval(result);
}

export const cleanMarkdownSyntax = (content: string) => {
    let result: string = content;

    result = _.replace(result, /\*\*\*\*\*\*(?=\s)/g, ''); // Remove lines
    result = _.replace(result, /\*\*\*\*\*(?=\s)/g, ''); // Remove lines
    result = _.replace(result, /\-\-\-\-\-\-/g, ''); // Remove lines
    result = _.replace(result, /\-\-\-(?=\s\s)/g, ''); // Remove lines
    result = _.replace(result, /\-\-(?=\s\s)/g, '***'); // Remove lines
    result = _.replace(result, /\*\*\*\*(?=\s)/g, ''); // Remove lines
    result = _.replace(result, /\*\*\*(?=\s)/g, ''); // Remove lines
    result = _.replace(result, /#{4,6}/g, '###### '); // Fix headings

    result = _.replace(result, /\+/g, '\\+'); // Remove unwanted bullet list
    result = _.replace(result, /(\d+\){1})/g, '$1.'); // Fixed to show number with parentheses.
    result = _.replace(result, /\*\*\*(?!\s)/g, '**'); // Alter bold rule from some cases
    result = _.replace(result, /\*\*(?=\W\W\s)/g, '** '); // Fix emoji conflict with bold
    result = _.replace(result, /######/g, '###### '); // Fix heading6
    result = _.replace(result, /######/g, '###### '); // Fix heading6

    return result;
}