import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { compose } from 'redux';
import { HomeListItem, HomeListItemType } from '../../model/HomeListItem';
import { IRootState } from '../../reducer';
import './home.scss';

export interface FavoritesListProps {
    specialtiesList?: HomeListItem[];
    leafletList?: HomeListItem[];
    cidList?: HomeListItem[];
    semiologyList?: HomeListItem[];
    doseList?: HomeListItem[];
    calculatorList?: HomeListItem[];
    scoreList?: HomeListItem[];
    laboratoryList?: HomeListItem[];
    criteriaList?: HomeListItem[];
    susList?: HomeListItem[];

    t: any;

    onItemClick: (item: HomeListItem) => void;

    history: any;
}

export const FavoritesList = (props: FavoritesListProps) => {
    const [filters, setFilter] = useState<HomeListItemType[]>([]);

    const onFilter = (type: HomeListItemType): void => {
        if (filters.includes(type)) {
            setFilter(filters.filter(it => it !== type));
        } else {
            setFilter(filters.concat(type));
        }
    };

    const onGoToSpeciaties = (): void => {
        props.history.push('/specialty');
    };
    const onGoToLeaflets = (): void => {
        props.history.push('/smart-bulas');
    };
    const onGoToCids = (): void => {
        props.history.push('/cid');
    };

    const listsAreEmpty: boolean =
        props.specialtiesList?.length === 0 &&
        props.leafletList?.length === 0 &&
        props.cidList?.length === 0 &&
        props.semiologyList?.length === 0 &&
        props.laboratoryList?.length === 0;
    const titleIcon: any = listsAreEmpty ? require('../../images/empty-star.svg') : require('../../images/orange-star.svg');
    const lineColor: string = listsAreEmpty ? 'title-line--gray' : 'title-line--orange';
    const diseaseIconClass: string = filters.includes(HomeListItemType.DISEASE)
        ? 'filters__disease-img filters__disease-img--orange'
        : 'filters__disease-img';
    const leafletIconClass: string = filters.includes(HomeListItemType.MEDICINE_LEAFLET)
        ? 'filters__leaflet-img filters__leaflet-img--orange'
        : 'filters__leaflet-img';
    const cidIconClass: string = filters.includes(HomeListItemType.CID) ? 'filters__cid-img filters__cid-img--orange' : 'filters__cid-img';
    const susIconClass: string = filters.includes(HomeListItemType.SUS) ? 'filters__sus-img filters__sus-img--orange' : 'filters__sus-img';
    return (
        <div className="home-list" style={{ width: '100%' }}>
            <div className="container">
                <Row style={{ marginTop: 30, marginBottom: 26, justifyContent: 'space-between' }}>
                    <div>
                        <Row className="align-items-center">
                            <img src={titleIcon} className="icon" alt={props.t('home.favorites')} />
                            <span className="title">{props.t('home.favorites')}</span>
                        </Row>
                        <div className={`title-line ${lineColor}`} />
                    </div>
                    <div className="filters">
                        <div className={diseaseIconClass} onClick={() => onFilter(HomeListItemType.DISEASE)} />
                        <div className={leafletIconClass} onClick={() => onFilter(HomeListItemType.MEDICINE_LEAFLET)} />
                        <div className={cidIconClass} onClick={() => onFilter(HomeListItemType.CID)} />
                        <div className={susIconClass} onClick={() => onFilter(HomeListItemType.SUS)} />
                    </div>
                </Row>
                {listsAreEmpty ? (
                    <div className="empty-list">
                        <span style={{ width: 269, textAlign: 'center' }}>
                            <span className="empty-list__text">{props.t('home.emptyFavorites1')}</span>
                            <span className="empty-list__text empty-list__text--highlighted" onClick={onGoToSpeciaties}>
                                {props.t('home.emptyFavorites2')}
                            </span>
                            <span className="empty-list__text">{props.t('home.emptyFavorites3')}</span>
                            <span className="empty-list__text empty-list__text--highlighted" onClick={onGoToLeaflets}>
                                {props.t('home.emptyFavorites4')}
                            </span>
                            <span className="empty-list__text">{props.t('home.emptyFavorites5')}</span>
                            <span className="empty-list__text empty-list__text--highlighted" onClick={onGoToCids}>
                                {props.t('home.emptyFavorites6')}
                            </span>
                            <span className="empty-list__text">{props.t('home.emptyFavorites7')}</span>
                        </span>
                    </div>
                ) : (
                    <div className="section-list">
                        <FavoritesListSection
                            key={1}
                            list={props.specialtiesList}
                            sectionTitle={props.t('home.specialties')}
                            iconClassName={'bacteria-img'}
                            onItemClick={props.onItemClick}
                            showList={filters == null || filters.length === 0 || filters.includes(HomeListItemType.DISEASE)}
                        />
                        <FavoritesListSection
                            key={2}
                            list={props.leafletList}
                            sectionTitle={props.t('home.leaflets')}
                            iconClassName={'leaflet-img'}
                            onItemClick={props.onItemClick}
                            showList={filters == null || filters.length === 0 || filters.includes(HomeListItemType.MEDICINE_LEAFLET)}
                        />
                        <FavoritesListSection
                            key={3}
                            list={props.cidList}
                            sectionTitle={props.t('home.cid')}
                            iconClassName={'cid-img'}
                            onItemClick={props.onItemClick}
                            showList={filters == null || filters.length === 0 || filters.includes(HomeListItemType.CID)}
                        />
                        <FavoritesListSection
                            key={4}
                            list={props.susList}
                            sectionTitle={props.t('home.sus')}
                            iconClassName={'sus-img'}
                            onItemClick={props.onItemClick}
                            showList={filters == null || filters.length === 0 || filters.includes(HomeListItemType.SUS)}
                        />
                        <FavoritesListSection
                            key={5}
                            list={props.laboratoryList}
                            sectionTitle={props.t('home.laboratory')}
                            iconClassName={'laboratory-img'}
                            onItemClick={props.onItemClick}
                            showList={filters == null || filters.length === 0 || filters.includes(HomeListItemType.LABORATORY)}
                        />
                        <FavoritesListSection
                            key={6}
                            list={props.semiologyList}
                            sectionTitle={props.t('home.semiology')}
                            iconClassName={'semiology-img'}
                            onItemClick={props.onItemClick}
                            showList={filters == null || filters.length === 0 || filters.includes(HomeListItemType.SEMIOLOGY)}
                        />
                        <FavoritesListSection
                            key={7}
                            list={props.doseList}
                            sectionTitle={props.t('home.pediatricDoses')}
                            iconClassName={'dose-img'}
                            onItemClick={props.onItemClick}
                            showList={filters == null || filters.length === 0 || filters.includes(HomeListItemType.PEDIATRIC_DOSE)}
                        />
                        <FavoritesListSection
                            key={8}
                            list={props.calculatorList}
                            sectionTitle={props.t('home.medicalCalculator')}
                            iconClassName={'calculate-img'}
                            onItemClick={props.onItemClick}
                            showList={filters == null || filters.length === 0 || filters.includes(HomeListItemType.MEDICAL_CALCULATOR)}
                        />
                        <FavoritesListSection
                            key={9}
                            list={props.scoreList}
                            sectionTitle={props.t('home.score')}
                            iconClassName={'calculate-img'}
                            onItemClick={props.onItemClick}
                            showList={filters == null || filters.length === 0 || filters.includes(HomeListItemType.SCORE)}
                        />
                        <FavoritesListSection
                            key={10}
                            list={props.criteriaList}
                            sectionTitle={props.t('home.criteria')}
                            iconClassName={'criteria-icon'}
                            onItemClick={props.onItemClick}
                            showList={filters == null || filters.length === 0 || filters.includes(HomeListItemType.CRITERIA)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export const FavoritesListSection = ({ sectionTitle, iconClassName, list, key, onItemClick, showList }) => {
    if (list == null || list.length === 0 || !showList) {
        return <div />;
    }
    return (
        <div key={key} className="section">
            <Row className="align-items-center">
                <div className={iconClassName} />
                <span className="section-title">{sectionTitle}</span>
            </Row>
            <ul className="item-list">
                {list.map((item, index) => (
                    <FavoritesListItem key={index} item={item} onItemClick={onItemClick} />
                ))}
            </ul>
        </div>
    );
};

export const FavoritesListItem = ({
    item,
    onItemClick
}: {
    item: HomeListItem;
    onItemClick: (item: HomeListItem) => void;
}): JSX.Element => {
    return (
        <li className="item" onClick={() => onItemClick(item)}>
            <span className="item-text">{item.name}</span>
            {item.subtitle != null && <span className="item-subtitle">{item.subtitle}</span>}
            <div className="item-line" />
        </li>
    );
};

const mapStateToProps = ({}: IRootState) => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(FavoritesList) as React.ComponentType<any>;
