import { SimpleId } from './simpleId';

export interface Favorite {
    medicineLeaflet?: SimpleId;
    cid?: SimpleId;
    susProcedure?: SimpleId;
    customer?: SimpleId;
    disease?: SimpleId;
    semiology?: SimpleId;
    laboratory?: SimpleId;
    flashcard?: SimpleId;
    medicalScore?: SimpleId;
    medicalCriteria?: SimpleId;
    medicalCalculator?: SimpleId;
    pediatricDose?: SimpleId;
    date?: string; // ZonedDateTime
    id?: number;
    isActive?: boolean;
    type: FavoriteType;
}

export enum FavoriteType {
    CID = 'CID',  
    DISEASE = 'DISEASE',  
    SEMIOLOGY = 'SEMIOLOGY',  
    MEDICAL_SCORE = 'MEDICAL_SCORE',  
    MEDICAL_CRITERIA = 'MEDICAL_CRITERIA',
    LABORATORY = 'LABORATORY',  
    FLASHCARD = 'FLASHCARD',
    PEDIATRIC_DOSE = 'PEDIATRIC_DOSE',
    MEDICAL_CALCULATOR = 'MEDICAL_CALCULATOR',  
    SUS = 'SUS',  
    MEDICINE_LEAFLET = 'MEDICINE_LEAFLET'
}